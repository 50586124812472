/**
 * @description This is the user util module that is used
 * throught the application. It operates on the user object
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module util/userUtil
 */

import PHONE_TYPE from "../enums/PHONE_TYPE";
import { getRoleByKey, meetsMin } from "../enums/ROLES";
/**
 * @description Gets the display name for a particular user.
 * Will default to email address if `user.firstName` and
 * `user.lastName` is not populated with some information
 *
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @example getDisplayName(user);
 *
 * @param {*} user a user object
 *
 * @returns {String | null} the concatination of `user.firstName`
 * and `user.lastName` if they are available OR the `email` address if the `user.firstName`
 * or `user.lastName` is not available OR null
 * @returns {String}
 */
export function getDisplayName(user) {
  if (!user) return null;

  let displayName = null;

  if (user.email) {
    displayName = user.email;
  }
  if (user.firstName && user.lastName) {
    displayName = `${user.firstName} ${user.lastName}`;
  }

  return displayName;
}

/**
 * @description Gets the initials for a particular user.
 * Will default to the first two intials of the email address
 * if `user.firstName` and `user.lastName` are not
 * populated with some information
 *
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @example getInitials(user);
 *
 * @param {*} user a user object
 *
 * @returns {String} the concatination of `user.firstName`
 * and `user.lastName` first letters if they are available
 * @returns {String} the first two letters of the email address
 * if the `user.firstName` or `user.lastName` is not available
 * @returns {null} if the user object is null or email is not
 * available
 */
export function getInitials(user) {
  if (!user) return null;

  let initials = null;

  if (user.email && user.email.length >= 2) {
    initials = `${user.email.charAt(0).toUpperCase()}${user.email
      .charAt(1)
      .toUpperCase()}`;
  }
  if (user.firstName && user.lastName) {
    initials = `${user.firstName.charAt(0).toUpperCase()}${user.lastName
      .charAt(0)
      .toUpperCase()}`;
  }

  return initials;
}

/**
 * Get the url for the user's profile page
 * @param {string} userId
 * @returns {String} A path for routing to the user profile page
 */
export function getProfileLink(userId) {
  if (!userId || typeof userId !== "string") return null;

  return `/users/${userId}`;
}

/**
 * @description Gets a users phone number phone number based on type.
 * By default the office phone number will be added
 * @since 1.0.0
 * @param {*} user user
 * @param {PHONE_TYPE} type the type of phone number to get. Will default to the
 * users office type
 * @returns {Array | null} A list of phone number object that match the `USER_PHONE_TYPE`
 */
export function getPhoneNumbers(user, type = PHONE_TYPE.OFFICE) {
  if (
    !user ||
    !user.phoneNumbers ||
    !user.phoneNumbers[type.key] ||
    !Array.isArray(user.phoneNumbers[type.key]) ||
    user.phoneNumbers[type.key].length <= 0
  ) {
    return null;
  }

  return user.phoneNumbers[type.key];
}

/**
 * @description Gets only one phone number for a user
 * @since 1.0.0
 * @param {*} user The user that you want to get the phone number of
 * @param {PHONE_TYPE} type the phone number type you want to get
 * @param {number} ordinal the position of their phone number in this
 * phone number type. Example. if they have 2 office phones (1-800 and a local
 * number) the ordinal will show the order that it is in the list where
 * 0 is the first item
 * @returns {*} a phone number for the user
 *
 */
export function getPhoneNumber(user, type = PHONE_TYPE.OFFICE, ordinal = 0) {
  const phoneNumbers = getPhoneNumbers(user, type);

  if (!phoneNumbers || ordinal >= phoneNumbers.length) return null;

  return phoneNumbers[ordinal];
}

/**
 * @description Gets the nicely formatted phone number
 * @since 1.0.0
 * @param {*} phoneNumber a phone number to format
 * @returns {string | null} a nicely formatted string
 */
export function getFormattedPhoneNumber(phoneNumber) {
  if (!phoneNumber || !phoneNumber.countryCode || !phoneNumber.number) {
    return null;
  }

  let phoneNumberString = `${phoneNumber.countryCode} ${phoneNumber.number}`;
  if (phoneNumber.extension) {
    phoneNumberString += ` ext. ${phoneNumber.extension}`;
  }

  return phoneNumberString;
}

/**
 * @description Sorts a list of users alphabetically
 * @since 1.0.0
 * @public
 * @param {Array} users an array of users to sort
 * @returns {Array | null} a sorted list of users
 */
export function sortUsers(users) {
  if (!users || !Array.isArray(users) || users.length === 0) {
    return null;
  }

  return users.sort((a, b) => {
    let aDisplayName = getDisplayName(a);
    let bDisplayName = getDisplayName(b);

    //casing should not matter
    if (aDisplayName) aDisplayName = aDisplayName.toLowerCase();
    if (bDisplayName) bDisplayName = bDisplayName.toLowerCase();

    if (aDisplayName < bDisplayName) return -1;
    else if (aDisplayName > bDisplayName) return 1;

    return 0;
  });
}

/**
 *
 * @param {Object} user the user to check if they can perform an action
 * @param {ROLE} minRoleRequired the minumum role required to perform the action
 * @param {Object} [org] The org to check if the user can perform the action on
 */
export function canDo(user, minRoleRequired, org = null) {
  if (!user || !user.userId) return false;

  if (!minRoleRequired || !minRoleRequired.key) return false;

  //the org takes precidence over app wide permission
  if (org && org.users && Array.isArray(org.users)) {
    const orgUser = org.users.find(orgUser => orgUser.userId === user.userId);

    //basic guards
    if (orgUser && orgUser.roleKey) {
      //get the role from the key
      const orgRole = getRoleByKey(orgUser.roleKey);

      //only if the app role is at least the min then return true
      if (meetsMin(orgRole, minRoleRequired)) return true;
    }
  }

  //check if user has app wide permissions
  if (user.apps && Array.isArray(user.apps)) {
    //get the app permission
    const app = user.apps.find(app => app.appName === "mss");

    //basic guards
    if (app && app.roleKey) {
      //get the role from the key
      const appRole = getRoleByKey(app.roleKey);

      //only if the app role is at least the min then return true
      if (meetsMin(appRole, minRoleRequired)) return true;
    }
  }

  return false;
}
