import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:7e1a4338-bb60-4a81-8114-df1d812b4da5",
    region: "us-east-1",
    userPoolId: "us-east-1_Z1P6ZIDe9",
    userPoolWebClientId: "211b0dhfeeqmerljjiin9p48t0",
    mandatorySignIn: true,
    oauth: {
      label: "AAIS Employees",
      domain: "aais-mss-dev.auth.us-east-1.amazoncognito.com",
      // redirectSignOut: "http://localhost:3000",
      // redirectSignIn: "http://localhost:3000",
      redirectSignOut: "https://mss-demo.aaisdirect.com",
      redirectSignIn: "https://mss-demo.aaisdirect.com",
      responseType: "code",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
        "name",
        "given_name",
        "family_name",
      ],
    },
  },
  API: {
    endpoints: [
      {
        name: "ORG",
        region: "us-east-1",
        endpoint: "https://besm06esh4.execute-api.us-east-1.amazonaws.com/v1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "USER",
        region: "us-east-1",
        endpoint: "https://cmkoww4gz2.execute-api.us-east-1.amazonaws.com/v1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
    ],
  },
});
