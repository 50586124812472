import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import OrgsTable from "../components/OrgsTable";

function OrgsScreen(props) {
  const { classes } = props;
  const me = useContext(MeContext);

  function getTitle() {
    if (me && me.firstName) return `${me.firstName}'s Organizations`;

    return `Your `;
  }

  return (
    <React.Fragment>
      <SummaryBar expanded={false} title={getTitle()} />
      <Grid container spacing={0} className={classes.grid}>
        {me && <OrgsTable orgIds={me.orgs} userId={me.userId} />}
      </Grid>
    </React.Fragment>
  );
}

const styles = theme => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    paddingRight: 10,
  },
  right: {
    paddingLeft: 10,
  },
});

export default withStyles(styles, { withTheme: true })(OrgsScreen);
