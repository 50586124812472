export const AmplifySignUpConfig = {
    header: "Create a new AAIS account",
    hideAllDefaults: true,
    signUpFields: [
      {
        label: 'Email',
        key: 'email',
        required: true,
        placeholder: 'Email',
        type: 'email',
        displayOrder: 1
      },
      {
        label: 'Username (Enter Email again)',
        key: 'username',
        required: true,
        placeholder: 'Email',
        type: 'email',
        displayOrder: 1
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        placeholder: 'Password',
        type: 'password',
        displayOrder: 2,
      },
    ]
  };