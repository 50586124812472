import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Geosuggest from "react-geosuggest";
import Grid from "@material-ui/core/Grid";

function PickAnAddressDialog(props) {
  const { classes, address } = props;

  const [addressComponents, setAddressComponents] = useState(
    address && address.addressComponents ? address.addressComponents : null
  );
  const [formattedAddress, setformattedAddress] = useState(
    address && address.formattedAddress ? address.formattedAddress : null
  );
  const [addressLine2, setAddressLine2] = useState(
    address && address.addressLine2 ? address.addressLine2 : null
  );
  const [placeId, setPlaceId] = useState(
    address && address.placeId ? address.placeId : null
  );

  function onSuggestSelect(suggestion) {
    if (!suggestion) return;

    if (suggestion.placeId) {
      setPlaceId(suggestion.placeId);
    }

    const gmaps = suggestion.gmaps;

    if (gmaps) {
      if (gmaps.formatted_address) {
        setformattedAddress(gmaps.formatted_address);
      }
      if (gmaps.address_components) {
        setAddressComponents(JSON.stringify(gmaps.address_components));
      }
    }
  }

  function handleOk() {
    let addressId;
    if (props.address && props.address.addressId) {
      addressId = props.address.addressId;
    }

    props.onChange(
      placeId,
      formattedAddress,
      addressComponents,
      addressLine2,
      addressId
    );
  }

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">Find Address</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.suggestContainer}>
          <Grid item xs={12} className={classes.suggestItem}>
            <Geosuggest
              onSuggestSelect={onSuggestSelect}
              initialValue={formattedAddress}
            />
          </Grid>
          {placeId && (
            <React.Fragment>
              <Grid item xs={12} className={classes.addressLine2}>
                <TextField
                  variant="outlined"
                  label="Additional Info"
                  placeholder="Suite 100 or Attn: John Doe"
                  fullWidth
                  value={addressLine2 ? addressLine2 : ""}
                  onChange={e => setAddressLine2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className={classes.maps}>
                <iframe
                  title={formattedAddress}
                  width="100%"
                  height="150"
                  frameBorder="0"
                  src={`https://www.google.com/maps/embed/v1/place?q=place_id:${placeId}&key=${apiKey}`}
                  allowFullScreen
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  dialogContent: {
    marginTop: 1,
    height: 280,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  suggestContainer: {},
  suggestItem: {},
  addressLine2: {
    marginTop: 8,
    marginBottom: 8,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(PickAnAddressDialog);
