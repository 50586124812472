/**
 * @description The enumeration of the Role types
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */
const ROLES = {
  /**
   * @constant {object}
   * @description A regular user
   * @since 1.0.0
   */
  READ_WRITE: {
    key: "read-write",
    title: "Manager",
    description: "Regular access",
  },
  /**
   * @constant {object}
   * @description An admin with elevated authorization
   * @since 1.0.0
   */
  ADMIN: {
    key: "admin",
    title: "Admin",
    description: "Elevated access",
  },
  /**
   * @constant {object}
   * @description A user will view only authorization
   * @since 1.0.0
   */
  READ_ONLY: {
    key: "read-only",
    title: "User",
    description: "Limited access",
  },
};

export default ROLES;

/**
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @description gets the role enum from just the key
 * @param {string} roleKey the key of any role. example ROLES.ADMIN.key
 * @returns {ROLES} returns a role
 */
export function getRoleByKey(roleKey) {
  const roleNames = Object.keys(ROLES);
  for (let i = 0; i < roleNames.length; i++) {
    const roleName = roleNames[i];
    const role = ROLES[roleName];
    if (role && role.key === roleKey) {
      return role;
    }
  }
  return null;
}

/**
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @description checks to see if `role` meets the minimum requirement of
 * `atLeastRole` and including `atLeastRole`.
 * @returns {boolean} true if `role` is of equal or higher than `atLeastRole`. false otherwise
 * @example
 * meetsMin(ROLES.READ_ONLY, ROLES.ADMIN); //will return false becasue ROLES.READ_ONLY is not at least ROLES.ADMIN
 * meetsMin(ROLES.ADMIN, ROLES.READ_ONLY); //will return true because ROLES.ADMIN is of higher privilige than ROLES.READ_ONLY
 * meetsMin(ROLES.READ_WRITE, ROLES.READ_WRITE); //will return true because ROLES.READ_WRITE is equal to and at least ROLES.READ_WRITE
 *
 */
export function meetsMin(role, atLeastRole) {
  if (!role || !role.key || !atLeastRole || !atLeastRole.key) {
    return false;
  }

  /*
  ####################################################################

  THIS CODE IS WRITTEN VERBOSE SO THAT ITS EASY TO READ AND TEST
  I could have returned a bunch of boolean logic in as few as
  two lines of code. But I chose not to. Also this code is
  very explicit in only returning true if the correct data is found.
  It will always return false otherwise
  
  ####################################################################
  */

  //need to be at least READ_ONLY
  if (atLeastRole.key === ROLES.READ_ONLY.key) {
    if (
      role.key === ROLES.READ_ONLY.key ||
      role.key === ROLES.READ_WRITE.key ||
      role.key === ROLES.ADMIN.key
    ) {
      return true;
    }
  }
  //need to be at least READ_WRITE
  else if (atLeastRole.key === ROLES.READ_WRITE.key) {
    if (role.key === ROLES.READ_WRITE.key || role.key === ROLES.ADMIN.key) {
      return true;
    }
  }
  //need to be at least ADMIN
  else if (atLeastRole.key === ROLES.ADMIN.key) {
    if (role.key === ROLES.ADMIN.key) {
      return true;
    }
  }

  return false;
}
