/**
 * @description This is the Contact Section component that is used
 * throught the application. It is used to quickly org a list
 * of contacts based on a particular contact type. It has the ability to
 * add a contact with its built in pick a user dialog
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module components/ContactSection
 */

import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PickAUserDialog from "./PickAUserDialog";
import UserCard from "../components/UserCard";
import LinearProgress from "@material-ui/core/LinearProgress";
import OrgContext from "../contexts/OrgContext";
import MeContext from "../contexts/MeContext";
import * as userUtil from "../util/userUtil";
import ROLES from "../enums/ROLES";
import Snackbar from "@material-ui/core/Snackbar";

function ContactSection(props) {
  const { classes, userIds } = props;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snack, setSnack] = React.useState({ open: false, note: null });

  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  function handleShowDialog() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function getContactsCards() {
    if (!userIds) return;

    return userIds.map(userId => {
      return (
        <UserCard key={userId} userId={userId} handleRemove={handleRemove} />
      );
    });
  }

  function handleCreate(typedValue) {
    props.handleCreateUser(typedValue, props.contactType);
  }

  async function handleAdd(user) {
    setLoading(true);
    setOpen(false);
    await props.handleAddContact(user, props.contactType);
    setLoading(false);
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} added as a ${
        props.contactType.title
      } contact`,
    });
  }

  async function handleRemove(user) {
    setLoading(true);
    await props.handleRemoveContact(user, props.contactType);
    setLoading(false);
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} removed as a ${
        props.contactType.title
      } contact`,
    });
  }

  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, note: null })}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <Paper className={classes.root} elevation={1}>
        <Grid
          container
          alignItems="flex-start"
          justify="space-between"
          className={classes.topSection}
        >
          <Grid item>
            <Typography variant="h5" component="h4">
              {props.contactType.title}
            </Typography>
          </Grid>
          <Grid item>
            {userUtil.canDo(me, ROLES.READ_WRITE, org) && (
              <Fab
                size="small"
                color="primary"
                aria-label="Add"
                onClick={handleShowDialog}
              >
                <AddIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="stretch">
          {getContactsCards()}
        </Grid>
        {open && (
          <PickAUserDialog
            handleClose={handleClose}
            handleAddUser={handleAdd}
            handleCreateUser={handleCreate}
          />
        )}
      </Paper>
    </React.Fragment>
  );
}

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
});
export default withStyles(styles, { withTheme: true })(ContactSection);
