import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PeopleIcon from "@material-ui/icons/People";
import PlaceIcon from "@material-ui/icons/Place";
import HistoryIcon from "@material-ui/icons/History";

const styles = theme => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  tab: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "red",
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
});

function OrgTabsSection(props) {
  const { classes, contactsTab, usersTab, addressesTab, historyTab } = props;

  const [selectedTab, setTab] = useState(0);

  function handleChange(event, newSelectedTab) {
    setTab(newSelectedTab);
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Contacts" icon={<ContactMailIcon />} />
          <Tab label="Users" icon={<PeopleIcon />} />
          <Tab label="Addresses" icon={<PlaceIcon />} />
          <Tab label="History" icon={<HistoryIcon />} />
        </Tabs>
      </Paper>
      {selectedTab === 0 && <React.Fragment>{contactsTab}</React.Fragment>}
      {selectedTab === 1 && <React.Fragment>{usersTab}</React.Fragment>}
      {selectedTab === 2 && <React.Fragment>{addressesTab}</React.Fragment>}
      {selectedTab === 3 && <React.Fragment>{historyTab}</React.Fragment>}
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(OrgTabsSection);
