/**
 * @description OrgApi Module. This will have all the API's needed
 * to interact with the backend API's for the Org
 * @module apis/OrgApi
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";
import * as commonUtil from "../util/commonUtil";
import { sortOrgs } from "../util/orgUtil";

/**
 * @author Finday Clarke
 *
 * @description Allows the querying of the User/Users.
 *
 * @param orgId pass the unique identifier for this User
 * if you are trying to get a specific User. If you would like
 * all the Users just call this method with
 * the `orgId` set it to undefined
 * @example
 * get("orgId123"); //will return the specific org
 *
 * @returns a particular org if a `orgId` is passed in
 *
 * @throws {NotAuthorizedException} Trying to access a partiular User
 * when the caller is not authorized
 */
export async function get(orgId) {
  if (!orgId) return null;

  let org;
  const path = `/orgs/${orgId}`;

  try {
    org = await API.get("ORG", path);
    storeOrgInCache(org);
  } catch (error) {
    console.log(error);
    alert(error.friendlyErrorMessage);
  }

  return org;
}

export async function getOrgs(orgIds) {
  if (!Array.isArray(orgIds)) return [];

  //convert array to comman separated list
  const orgIdsString = commonUtil.filterAndJoinResults(orgIds);

  if (!orgIdsString) return [];

  const path = `/orgs?orgIds=${orgIdsString}`;

  try {
    const orgs = await API.get("ORG", path);
    storeOrgsInCache(orgs);
    return sortOrgs(orgs);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

export function getOrgsFromCache(orgIds) {
  if (!orgIds || !Array.isArray(orgIds)) return;

  const orgs = [];
  orgIds.forEach(orgId => {
    const org = getFromCache(orgId);
    if (org) orgs.push(org);
  });

  return sortOrgs(orgs);
}

/**
 * @description Adds a user as a contacts to the org
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @async
 * @param {string} userId the userId of the user you would like to add
 * as a contact
 * @param {string} orgId the orgId of the org you would like to add
 * this contact to
 * @param {ORG_CONTACT_TYPES} contactType the contact type
 * that you would like to add
 * @returns {boolean} true if the add was successful. false if the add
 * was unsuccessful
 * @example
 * addContact("userId1", "orgId1", ORG_CONTACT_TYPES.STATISTICAL_REPORTING);
 */
export async function addContact(userId, orgId, contactType) {
  if (!userId || !orgId || !contactType || !contactType.key) return false;

  const path = `/orgs/${orgId}/contacts/${contactType.key}`;
  const body = { userId: userId };

  const init = {
    body: body,
  };

  try {
    await API.post("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

/**
 * @description Remove a user as a contacts to the org
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @async
 * @param {string} userId the userId of the user you would like to remove
 * as a contact
 * @param {string} orgId the orgId of the org you would like to
 * remove this contact from
 * @param {ORG_CONTACT_TYPES} contactType the contact type
 * that you would like to remove this user as
 * @returns {boolean} true if the remove was successful. false if the remove
 * was unsuccessful
 * @example
 * removeContact("userId1", "orgId1", ORG_CONTACT_TYPES.STATISTICAL_REPORTING);
 */
export async function removeContact(userId, orgId, contactType) {
  if (!userId || !orgId || !contactType || !contactType.key) return false;

  const path = `/orgs/${orgId}/contacts/${contactType.key}`;
  const body = { userId: userId };

  const init = {
    body: body,
  };

  try {
    await API.del("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

/**
 * @description Adds a user to the org
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @async
 * @param {string} userId the userId of the user you would like to add
 * @param {string} orgId the orgId of the org you would like to add
 * this user to
 * @param {ROLES} role the role type
 * that you would like to add
 * @returns {boolean} true if the add was successful. false if the add
 * was unsuccessful
 * @example
 * addUser("userId1", "orgId1", ROLES.ADMIN);
 */
export async function addUser(userId, orgId, role) {
  if (!userId || !orgId || !role || !role.key) return false;

  const path = `/orgs/${orgId}/users`;
  const body = { userId, roleKey: role.key };

  const init = {
    body: body,
  };

  try {
    await API.post("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

/**
 * @description Removes a user from the org. This will remove the user
 * competely from the org where other references are located
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @async
 * @param {string} userId the userId of the user you would like to remove
 * @param {string} orgId the orgId of the org you would like to
 * remove this user from
 * @returns {boolean} true if the remove was successful. false if the remove
 * was unsuccessful
 * @example
 * removeUser("userId1", "orgId1");
 */
export async function removeUser(userId, orgId) {
  if (!userId || !orgId) return false;

  const path = `/orgs/${orgId}/users/${userId}`;

  try {
    await API.del("ORG", path);
  } catch (error) {
    console.log(error);
  }

  return true;
}

/**
 * @description Audits a user removal from the org. Will tell what
 * will be removed when you delete a user frmo the org
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @async
 * @param {string} userId the userId of the user you would like to remove
 * @param {string} orgId the orgId of the org you would like to
 * remove this user from
 * @returns {object} a list of places that will be cleaned up with the deletion
 * of this user
 * @example
 * removeUserAudit("userId1", "orgId1");
 */
export async function removeUserAudit(userId, orgId) {
  if (!userId || !orgId) return false;

  const path = `/orgs/${orgId}/users/${userId}?audit=true`;

  try {
    return await API.del("ORG", path);
  } catch (error) {
    console.log(error);
  }
}

/**
 * @description Updates a user in the org
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @async
 * @param {string} userId the userId of the user you would like to update
 * @param {string} orgId the orgId of the org you would like to
 * update the user in
 * @param {ROLES} role the role that this user will have
 * @returns {boolean} true if the update was successful. false if the update
 * was unsuccessful
 * @example
 * updateUser("userId1", "orgId1", ROLES.ADMIN);
 */
export async function updateUser(userId, orgId, role) {
  if (!userId || !orgId || !role || !role.key) return false;

  const path = `/orgs/${orgId}/users/${userId}`;
  const body = { roleKey: role.key };

  const init = {
    body: body,
  };

  try {
    await API.put("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

export async function addAddress(
  orgId,
  addressType,
  placeId,
  formattedAddress,
  addressLine2,
  addressComponents
) {
  if (
    !orgId ||
    !addressType ||
    !addressType.key ||
    !placeId ||
    !formattedAddress ||
    !addressComponents
  )
    return false;

  const path = `/orgs/${orgId}/addresses/${addressType.key}`;
  const body = { placeId, formattedAddress, addressComponents, addressLine2 };

  const init = {
    body: body,
  };

  try {
    await API.post("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

export async function updateAddress(
  orgId,
  addressType,
  placeId,
  formattedAddress,
  addressLine2,
  addressComponents,
  addressId
) {
  if (
    !addressId ||
    !orgId ||
    !addressType ||
    !addressType.key ||
    !placeId ||
    !formattedAddress ||
    !addressComponents
  )
    return false;

  const path = `/orgs/${orgId}/addresses/${addressType.key}/ids/${addressId}`;
  const body = { placeId, formattedAddress, addressComponents, addressLine2 };

  const init = {
    body: body,
  };

  try {
    await API.put("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

export async function deleteAddress(orgId, addressType, addressId) {
  if (!addressId || !orgId || !addressType || !addressType.key) return false;

  const path = `/orgs/${orgId}/addresses/${addressType.key}/ids/${addressId}`;

  const init = {};

  try {
    await API.del("ORG", path, init);
  } catch (error) {
    console.log(error);
  }

  return true;
}

/**
 * @author Finday Clarke
 *
 * @description Get transactions for a specific org
 *
 * @param {string} orgId pass the unique identifier of the org
 * the `orgId` set it to undefined
 * @param {object} [exclusiveStartKey] used to paginate the results. use the
 * `lastEvaluatedKey` from the previous query to get the next batch of data
 * @example
 * get("orgId123"); //will return the specific org transactions
 *
 * @returns a particular org if a `orgId` is passed in
 *
 * @throws {NotAuthorizedException} Trying to access a partiular User
 * when the caller is not authorized
 */
export async function getTransactions(orgId, exclusiveStartKey = undefined) {
  if (!orgId) return null;

  let transactions;
  const path = `/orgs/${orgId}/transactions`;
  const init = {
    body: { exclusiveStartKey },
  };

  try {
    transactions = await API.post("ORG", path, init);
  } catch (error) {
    console.log(error);
    alert(error.friendlyErrorMessage);
  }

  return transactions;
}

/**
 * @description Gets a copy of a specific org from local storage.
 * This helps with optimization so that we can fetch from cache first before going
 * to the REST API's
 * @private
 * @author Findlay Clarke <findlayc@aaislonline.com>
 * @param {*} orgId the unique orgId for the org to get
 * @since 1.0.0
 */
export function getFromCache(orgId) {
  if (!orgId) return;

  const orgJSON = localStorage.getItem(orgId);

  try {
    return JSON.parse(orgJSON);
  } catch (error) {
    console.error(`could not parse org object ${orgJSON}`, error);
  }

  return null;
}

/*
###############################################################################
                                PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/

/**
 * @description Sets a copy of a specific org to local storage.
 * This helps with optimization so that we can fetch from cache first before going
 * to the REST API's
 * @private
 * @author Findlay Clarke <findlayc@aaislonline.com>
 * @param {*} orgId the unique orgId for the org to get
 * @since 1.0.0
 */
function storeOrgInCache(org) {
  if (!org || !org.orgId) return;
  localStorage.setItem(org.orgId, JSON.stringify(org));
}

function storeOrgsInCache(orgs) {
  if (!orgs || !Array.isArray(orgs)) return;

  orgs.forEach(org => storeOrgInCache(org));
}

/*
###############################################################################
                                END PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
