import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import OrgsTable from "../components/OrgsTable";
import Typography from "@material-ui/core/Typography";

function HomeScreen(props) {
  const { classes } = props;
  const me = useContext(MeContext);

  function getTitle() {
    if (me && me.firstName) return `Welcome, ${me.firstName}`;
    else if (me && me.email) return `Welcome, ${me.email}`;
    return `Welcome`;
  }

  function getWelcome() {
    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.welcome}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h1">AAIS Main Event 2019</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3">Member Self Service Demo</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Currently a prototype</Typography>
          </Grid>
          <Grid item>
            <Typography>AAIS Copyright 2019</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <SummaryBar expanded={true} title={getTitle()}>
        {getWelcome()}
      </SummaryBar>
      <Grid container spacing={0} className={classes.grid}>
        <Grid item container xs={12} className={classes.right} />
        {me && <OrgsTable orgIds={me.orgs} userId={me.userId} />}
      </Grid>
    </React.Fragment>
  );
}

const styles = theme => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  welcome: {
    paddingTop: 20,
    height: "calc(100vh - 141px)",
    textAlign: "center",
  },
});

export default withStyles(styles, { withTheme: true })(HomeScreen);
