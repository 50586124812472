import React, { useContext } from "react";
import { AppBar, Toolbar, IconButton, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavLink, Link } from "react-router-dom";
import MeContext from "../contexts/MeContext";
import * as userUtil from "../util/userUtil";
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  activeButtonStyle: {
    //backgroundColor: theme.palette.primary.main,
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  activeMeStyle: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
  },
});

function NavigationBar(props) {
  const { classes } = props;
  const me = useContext(MeContext);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            component={Link}
            to="/"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <img src="/aais-logo-with-letters-32.png" alt="Home" />
          </IconButton>
          <div className={classes.grow} />
          <Button
            component={NavLink}
            className={classes.button}
            exact
            to="/orgs"
            activeClassName={classes.activeButtonStyle}
            color="inherit"
          >
            Orgs
          </Button>
          {/* <Button
            component={NavLink}
            to="/users"
            //activeClassName={classes.activeButtonStyle}
            color="inherit"
          >
            Users
          </Button> */}
          {me && (
            <Avatar
              className={classes.avatar}
              alt={userUtil.getDisplayName(me)}
              src={me.picture}
              component={Link}
              to={userUtil.getProfileLink(me.userId)}
              activeClassName={classes.activeMeStyle}
            >
              {userUtil.getInitials(me)}
            </Avatar>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(NavigationBar);
