import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Downshift from "downshift";
import deburr from "lodash/deburr";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import * as UserApi from "../apis/UserApi";
import * as userUtil from "../util/userUtil";
import { getDisplayName } from "../util/userUtil";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

function PickAUserDialog(props) {
  const { classes } = props;

  const [users, setUsers] = useState(UserApi.getAllFromCache());
  const [selectedUser, setSelectedUser] = useState(null);
  const [typedValue, setTypedValue] = useState(null);

  useEffect(() => {
    UserApi.getAll().then(users => setUsers(users));
  }, []);

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  }) {
    const isHighlighted = highlightedIndex === index;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.userId}
        selected={isHighlighted}
        component="div"
      >
        <Grid container spacing={2} zeroMinWidth>
          <Grid item>
            <Avatar
              className={classes.avatar}
              alt={userUtil.getDisplayName(suggestion)}
              src={suggestion.picture}
            >
              {userUtil.getInitials(suggestion)}
            </Avatar>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="center"
            xs
            zeroMinWidth
          >
            <Grid item>
              <Typography
                noWrap
                className={classes.title}
                color="primary"
                gutterBottom
              >
                {userUtil.getDisplayName(suggestion)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography noWrap className={classes.email}>
                {suggestion.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MenuItem>
    );
  }

  async function handleCreateUser() {
    const user = await UserApi.createUser(typedValue);
    props.handleAddUser(user);
  }

  function getSuggestions(value) {
    if (!value) return [];

    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : users.filter(user => {
          const keep = count < 5 && validSuggestion(user, inputValue);

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  function validSuggestion(user, inputValue) {
    const displayName = getDisplayName(user).toLowerCase();
    const email = user.email.toLowerCase();
    const tokens = inputValue.split(" ");

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i].toLowerCase();
      if (!displayName.includes(token) && !email.includes(token)) return false;
    }

    return true;
  }

  function validEmail(typedValue) {
    if (!typedValue) return false;
    return typedValue.match(/\S+@\S+\.\S+/);
  }

  function getDownShiftControl(props) {
    const { classes } = props;
    return (
      <Downshift
        onChange={user => setSelectedUser(user)}
        itemToString={user => getDisplayName(user)}
        onInputValueChange={text => {
          if (text) {
            setTypedValue(text); //TODO make sure to set it if its a real email
          }
          setSelectedUser(null);
        }}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                placeholder: "john.doe@your-insurace.com",
              }),
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      data-testid="pickAUserDialog"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">Find User</DialogTitle>
      {users && (
        <DialogContent style={{ height: 280 }}>
          {getDownShiftControl(props)}
        </DialogContent>
      )}
      <DialogActions>
        {!selectedUser && (
          <Button
            onClick={() => {
              handleCreateUser(typedValue);
            }}
            color="primary"
            variant="outlined"
            disabled={!validEmail(typedValue)}
          >
            Create User {typedValue}
          </Button>
        )}
        <Button
          onClick={() => props.handleAddUser(selectedUser)}
          color="primary"
          variant="contained"
          disabled={!selectedUser}
        >
          {props.addText ? props.addText : "Assign User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    textDecoration: "none",
    lineHeight: 1,
  },
  email: {
    color: "gray", //theme.palette.grey[100],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(PickAUserDialog);
