/**
 * @description UserApi Module. This will have all the API's needed
 * to interact with the backend API's for the User
 * @module apis/UserApi
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @public
 */

import { API, Auth } from "aws-amplify";
import { sortUsers } from "../util/userUtil";
import * as commonUtil from "../util/commonUtil";

/**
 * @description Allows the querying of a particular User.
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @param {String} userId the unique identifier for the user
 * you are trying to get.
 *
 * @example
 * get("userId123"); //will return the specific user
 *
 * @returns {* | null} a particular user if a `userId` is passed in
 *
 */
export async function get(userId) {
  if (!userId || typeof userId != "string" || userId.trim().length === 0)
    return null;

  const path = `/users/${userId}`;

  try {
    const user = await API.get("USER", path);
    storeUserInCache(user);
    return user;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Helper method to get access to the current logged in user
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @returns {object} a user object
 */
export async function getMe(fromCache = false) {
  const info = await Auth.currentAuthenticatedUser();
  if (!info || !info.attributes || !info.attributes.sub) return null;

  const userId = info.attributes.sub;

  if (fromCache) {
    return getFromCache(userId);
  }

  const me = await get(userId);
  return me;
}

/**
 * @description Allows the querying of a multiple Users.
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @param {Array} userIds an array of userIds for the users
 *
 * @example
 * useUsers(["userId1", "userId2"]); //will return the specific user
 *
 * @returns {Array} an array of users
 *
 *
 */
export async function getUsers(userIds) {
  if (!Array.isArray(userIds)) return [];

  //convert array to comman separated list
  const userIdsString = commonUtil.filterAndJoinResults(userIds);

  if (!userIdsString) return [];

  const path = `/users?userIds=${userIdsString}`;

  try {
    const users = await API.get("USER", path);
    storeUsersInCache(users);
    return sortUsers(users);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Allows the querying all Users. Be very careful.
 * This will be very slow as it has to look up all users
 *
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @example
 * getAll(); //will return a array of all users
 *
 * @returns {Array} an array of all users
 *
 */
export async function getAll() {
  const path = "/users";

  try {
    const users = await API.get("USER", path);

    if (users && users.length > 0) {
      //do some storing in the cache
      storeUsersInCache(users);
      const userIds = users.map(user => user.userId);
      const userIdsString = commonUtil.filterAndJoinResults(userIds);
      localStorage.setItem("getAllUsersUserIds", userIdsString);
    }

    return sortUsers(users);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Allows the querying all Users from the cache.
 *
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @example
 * getAllFromCache(); //will return a array of all users
 *
 * @returns {Array} an array of all users
 *
 */
export function getAllFromCache() {
  const userIdsString = localStorage.getItem("getAllUsersUserIds");

  if (!userIdsString) return [];

  const userIds = userIdsString.split(",");
  const users = getUsersFromCache(userIds);

  return users;
}

/**
 * @description Creates a user
 *
 * @author Finday Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 *
 * @public @async
 *
 * @example
 * createUser("findlay@gmail.com"); //will return a created user
 *
 * @returns {Object}
 *
 */
export async function createUser(email) {
  const path = "/users";

  const params = {
    body: {
      email: email,
    },
  };

  try {
    const user = await API.post("USER", path, params);
    return user;
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage;
  }
}

/**
 * @description Updated a users name information
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @param {string} userId the unique id of the user you are updating
 * @param {string} firstName the new first name
 * @param {string} lastName the new last name
 * @returns {boolean} true if the update was successful
 */
export async function updateName(userId, firstName, lastName) {
  if (!userId || !firstName || !lastName) return false;

  firstName = firstName.trim();
  lastName = lastName.trim();

  if (!firstName || !lastName) return false;

  const path = `/users/${userId}`;
  const params = {
    body: {
      firstName: firstName,
      lastName: lastName,
    },
  };

  try {
    await API.put("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function updateEmail(userId, emailType, email) {
  if (!userId || !emailType || !email.key || !email) return false;

  //TODO validate email
  email = email.trim();

  const path = `/users/${userId}/emails/${email.key}`;
  const params = {
    body: {
      email: email,
    },
  };

  try {
    await API.post("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}
/**
 *
 * @param {string} userId The userId whose phone number to update
 * @param {} phoneType
 * @param {String} number
 * @param {String} countryCode
 * @param {String} extension
 */
export async function addPhoneNumber(
  userId,
  phoneType,
  number,
  countryCode = "+1",
  extension
) {
  if (!userId || !phoneType || !phoneType.key || !number) return false;

  //TODO validate phone number
  number = number.trim();
  countryCode = countryCode.trim();

  const path = `/users/${userId}/phone-numbers/${phoneType.key}`;
  const params = {
    body: {
      phoneNumber: number,
      countryCode: countryCode,
      extension: extension,
    },
  };

  try {
    await API.post("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function updatePhoneNumber(
  userId,
  phoneType,
  phoneNumberId,
  number,
  countryCode = "+1",
  extension
) {
  if (!userId || !phoneType || !phoneType.key || !number || !phoneNumberId)
    return false;

  //TODO validate phone number
  number = number.trim();
  countryCode = countryCode.trim();

  const path = `/users/${userId}/phone-numbers/${
    phoneType.key
  }/ids/${phoneNumberId}`;
  const params = {
    body: {
      phoneNumber: number,
      countryCode: countryCode,
      extension: extension,
    },
  };

  try {
    await API.put("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function deletePhoneNumber(userId, phoneType, phoneNumberId) {
  if (!userId || !phoneType || !phoneType.key || !phoneNumberId) return false;

  const path = `/users/${userId}/phone-numbers/${
    phoneType.key
  }/ids/${phoneNumberId}`;

  try {
    await API.del("USER", path);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function changeProfilePicture(
  userId,
  contentType,
  fileExtension,
  imageInBase64
) {
  if (!userId || !contentType || !fileExtension || !imageInBase64) return false;

  //TODO validate file extension and content type
  fileExtension = fileExtension.trim();
  contentType = contentType.trim();

  const path = `/users/${userId}/profile-pictures`;
  const params = {
    body: {
      contentType: contentType,
      fileExtension: fileExtension,
      imageInBase64: imageInBase64,
    },
  };

  try {
    await API.post("USER", path, params);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export async function deleteProfilePicture(
  userId,
  contentType,
  fileExtension,
  imageInBase64
) {
  if (!userId || !contentType || !fileExtension || !imageInBase64) return false;

  const path = `/users/${userId}/profile-pictures`;

  try {
    await API.del("USER", path);
  } catch (error) {
    alert(error.friendlyErrorMessage);
    return false;
  }

  return true;
}

export function getFromCache(userId) {
  if (!userId) return;

  const userJSON = localStorage.getItem(userId);

  try {
    return JSON.parse(userJSON);
  } catch (error) {
    console.error(`could not parse user object ${userJSON}`);
  }

  return null;
}

export function getUsersFromCache(userIds) {
  if (!userIds || !Array.isArray(userIds)) return;

  const users = [];
  userIds.forEach(userId => {
    const user = getFromCache(userId);
    if (user) users.push(user);
  });
  return sortUsers(users);
}

/*
###############################################################################
                                PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
function storeUserInCache(user) {
  if (!user || !user.userId) return;
  localStorage.setItem(user.userId, JSON.stringify(user));
}

function storeUsersInCache(users) {
  if (!users || !Array.isArray(users)) return;

  users.forEach(user => storeUserInCache(user));
}

/*
###############################################################################
                                END PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
