import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CardHeader } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import * as UserApi from "../apis/UserApi";
import * as OrgApi from "../apis/OrgApi";
import * as userUtil from "../util/userUtil";
import { Link } from "react-router-dom";

function TransactionItem(props) {
  const { classes, transaction } = props;

  const [user, setUser] = useState(UserApi.getFromCache(transaction.userId));
  useEffect(() => {
    UserApi.get(transaction.userId).then(user => setUser(user));
  }, [transaction.userId]);

  const [org, setOrg] = useState(OrgApi.getFromCache(transaction.resourceId));
  useEffect(() => {
    OrgApi.get(transaction.resourceId).then(org => setOrg(org));
  }, [transaction.resourceId]);

  function getText() {
    const text = `${userUtil.getDisplayName(user)} `;

    switch (transaction.resourceName) {
      case "orgs.users": {
        switch (transaction.action) {
          case "create":
            return `${text} added a user to ${org.name}`;
          case "update":
            return `${text} updated a user in ${org.name}`;
          case "delete":
            return `${text} removed a user from ${org.name}`;
          default:
            return `${text} made changes to ${org.name}`;
        }
      }
      case "orgs.contacts": {
        switch (transaction.action) {
          case "create":
            return `${text} added a contact to ${org.name}`;
          case "update":
            return `${text} updated a contact in ${org.name}`;
          case "delete":
            return `${text} removed a contact from ${org.name}`;
          default:
            return `${text} made changes to ${org.name}`;
        }
      }
      case "orgs.addresses": {
        switch (transaction.action) {
          case "create":
            return `${text} added an address to ${org.name}`;
          case "update":
            return `${text} updated an address in ${org.name}`;
          case "delete":
            return `${text} removed an address from ${org.name}`;
          default:
            return `${text} made changes to ${org.name}`;
        }
      }
      default:
        return `${text} made changes to ${org.name}`;
    }
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            alt={userUtil.getDisplayName(user)}
            src={user.picture}
            component={Link}
            to={userUtil.getProfileLink(user.userId)}
          >
            {userUtil.getInitials(user)}
          </Avatar>
        }
        title={
          <Typography
            className={classes.title}
            color="primary"
            gutterBottom
            component={Link}
            to={userUtil.getProfileLink(user.userId)}
            noWrap
          >
            {userUtil.getDisplayName(user)}
          </Typography>
        }
        subheader={new Date(transaction.created).toLocaleString()}
      />
      <CardContent>{getText()}</CardContent>
    </Card>
  );
}

const styles = theme => ({
  grid: {
    flexGrow: 1,
  },
  card: {
    flexGrow: 1,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    textDecoration: "none",
  },
});

export default withStyles(styles, { withTheme: true })(TransactionItem);
