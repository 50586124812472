import React, { useState, useEffect } from "react";
import * as OrgApi from "../apis/OrgApi";
import { withStyles } from "@material-ui/core/styles";
import TransactionItem from "./TransactionItem";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

function Transactions(props) {
  const { classes, orgId } = props;
  const [loading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    OrgApi.getTransactions(orgId).then(results => {
      setLoading(false);
      if (results && results.data) {
        setTransactions(results.data);
      }
    });
  }, [orgId]);
  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          alignItems="stretch"
          spacing={2}
          className={classes.grid}
        >
          {transactions &&
            transactions.map(transaction => {
              return (
                <Grid key={transaction.transactionId} item xs={12}>
                  <TransactionItem transaction={transaction} />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const styles = theme => ({
  grid: {
    flexGrow: 1,
    paddingTop: 20,
  },
});

export default withStyles(styles, { withTheme: true })(Transactions);
