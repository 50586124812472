import React from "react";
/**
 * @description A context that can be passed down to child components
 * so that they can have access to the dispatch method that can call
 * reload back up to the parent screen. This makes it easy so that children
 * components can tell their parent screen that something has changed and
 * you should reload the data
 */
const ReloadContext = React.createContext(null);

export default ReloadContext;
