/**
 * @description This is the org util module that is used
 * throught the application and specializes on the org object
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module util/orgUtil
 */

/**
 * @description Gets all the `userId`s from the `org.users` array and
 * concatinate them together with commas (,) so that it can be used to call
 * the rest API easily
 * @example `getUserIdsString(org)`
 * @since 1.0.0
 * @public
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @param {*} org the org item
 * @returns {String | null} a string with all the `userIds` concatinated by
 * a comma. For example `userId1,userId2`
 */
export function getUserIds(org) {
  //check for bad input
  if (
    !org ||
    !org.users ||
    !Array.isArray(org.users) ||
    org.users.length <= 0
  ) {
    return [];
  }

  //get all the results that have valid values
  //eslint-disable-next-line
  const unfilteredResults = org.users.map(user => {
    if (user.userId) {
      return user.userId.trim(); //trim whitespace
    }
  });

  return unfilteredResults.filter(userId => userId);
}

/**
 * @description Gets all the `userId`s from the `org.contacts` array and
 * concatinate them together with commas (,) so that it can be used to call
 * the rest API easily
 * @example `getContactsUserIds(org)`
 * @since 1.0.0
 * @public
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @param {*} org the org item
 * @returns {String | null} a string with all the `userIds` concatinated by
 * a comma. For example `userId1,userId2`
 */
export function getContactsUserIds(org, contactType) {
  if (
    !contactType ||
    !org ||
    !org.contacts ||
    !org.contacts[contactType.key] ||
    !Array.isArray(org.contacts[contactType.key]) ||
    org.contacts[contactType.key].length <= 0
  ) {
    return [];
  }

  // get all the results that have valid values
  // eslint-disable-next-line
  const unfilteredResults = org.contacts[contactType.key].map(user => {
    if (user.userId) {
      return user.userId.trim(); //trim the whitespace
    }
  });

  return unfilteredResults.filter(userId => userId);
}

/**
 * @description Helper method that gets the userId strings
 * @param {*} org the org object
 * @param {String} [role] the requested filtered role. Defaults to
 * get all the users. valid values are `admin`, `read-only` or `user`
 */
export function getOrgUsersIds(org, role = undefined) {
  if (!org || !org.users || org.users.length <= 0) return null;

  const filteredUsers = org.users.filter(user => {
    if (!role) {
      return true; //requesting all as role is `undefined`
    } else if (role === user.roleKey) {
      return true;
    }

    return false;
  });

  if (!filteredUsers) return [];

  //ony return the userIds
  return filteredUsers.map(user => user.userId);
}

/**
 * Get the url for the org's page
 * @param {string} orgId
 * @returns {String} A path for routing to the org page
 */
export function getOrgLink(orgId) {
  if (!orgId || typeof orgId !== "string") return null;

  return `/orgs/${orgId}`;
}

/**
 * @description Sorts a list of users alphabetically
 * @since 1.0.0
 * @public
 * @param {Array} users an array of users to sort
 * @returns {Array | null} a sorted list of users
 */
export function sortOrgs(orgs) {
  if (!orgs || !Array.isArray(orgs)) {
    return null;
  }

  return orgs.sort((a, b) => {
    let aDisplayName = a && a.name ? a.name : "N/A";
    let bDisplayName = b && b.name ? b.name : "N/A";

    //casing should not matter
    if (aDisplayName) aDisplayName = aDisplayName.toLowerCase();
    if (bDisplayName) bDisplayName = bDisplayName.toLowerCase();

    if (aDisplayName < bDisplayName) return -1;
    else if (aDisplayName > bDisplayName) return 1;

    return 0;
  });
}
