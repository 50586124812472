/**
 * @description This is the Screen Not Found Screen module that is used
 * to serve the 404 View
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/ScreenNotFoundScreen
 */
import React from "react";

export default class ScreenNotFoundScreen extends React.Component {
  render() {
    return <h1>404</h1>;
  }
}
