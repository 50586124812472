import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import BusinessIcon from "@material-ui/icons/Business";

const styles = theme => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  tab: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "red",
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
});

function UserTabsSection(props) {
  const { classes, infoTab, orgsTab } = props;

  const [selectedTab, setTab] = useState(0);

  function handleChange(event, newSelectedTab) {
    setTab(newSelectedTab);
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Info" icon={<ContactMailIcon />} />
          <Tab label="Associated Orgs" icon={<BusinessIcon />} />
        </Tabs>
      </Paper>
      {selectedTab === 0 && <React.Fragment>{infoTab}</React.Fragment>}
      {selectedTab === 1 && <React.Fragment>{orgsTab}</React.Fragment>}
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(UserTabsSection);
