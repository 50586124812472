export const AaisTheme = {
  palette: {
    primary: {
      main: "#1f4287",
      light: "#5091cd",
      dark: "#143068",
      contrastText: "#ffffff",
    },
    action: {
      hover: "rgba(213, 235, 255, 0.08)",
      hoverOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: '"Open Sans", "sans-serif"',
    useNextVariants: true,
  },
};
