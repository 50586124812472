import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightMedium,
  },
  expansionPanel: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px !important",
    color: "white",
  },
  expandMoreIcon: {
    backgroundColor: "white",
    borderRadius: 20,
  },
  expansionPanelDetails: {
    display: "flex",
    //marginLeft: 50,
    // marginRight: 50,
    justifyContent: "flexStart",
    flexWrap: "wrap",
  },
  addressCard: {
    margin: 10,
    display: "flex",
  },
});

function SummaryBar(props) {
  const { classes, title, expanded, children } = props;

  return (
    <div className={classes.root}>
      <ExpansionPanel
        className={classes.expansionPanel}
        defaultExpanded={expanded}
      >
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon
              className={classes.expandMoreIcon}
              color="primary"
            />
          }
        >
          <Typography className={classes.heading} color="inherit">
            {title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <Grid container spacing={3} alignItems="stretch">
            {children}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(SummaryBar);
