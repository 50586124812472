import React, { useEffect, useState } from "react";
import { withAuthenticator } from "aws-amplify-react";
import NavigationBar from "./components/NavigationBar";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { AaisTheme } from "./styles/AaisTheme";
import { AmplifyTheme } from "./styles/AmplifyTheme";
import { AmplifySignUpConfig } from "./config/AmplifySignupConfig";
import { HashRouter, Route, Switch } from "react-router-dom";
import UsersScreen from "./screens/UsersScreen";
import UserScreen from "./screens/UserScreen";
import ScreenNotFoundScreen from "./screens/ScreenNotFoundScreen";
import OrgScreen from "./screens/OrgScreen";
import OrgsScreen from "./screens/OrgsScreen";
import HomeScreen from "./screens/HomeScreen";
import MeContext from "./contexts/MeContext";
import * as UserApi from "./apis/UserApi";

require("./amplify");
function App(props) {
  const [me, setMe] = useState(UserApi.getMe(true));

  useEffect(() => {
    UserApi.getMe().then(result => setMe(result));
  }, []);

  return (
    <MuiThemeProvider theme={createMuiTheme(AaisTheme)}>
      <HashRouter>
        <MeContext.Provider value={me}>
          <NavigationBar />
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/orgs" component={OrgsScreen} />
            <Route exact path="/orgs/:orgId" component={OrgScreen} />
            <Route exact path="/users" component={UsersScreen} />
            <Route exact path="/users/:userId" component={UserScreen} />
            <Route component={ScreenNotFoundScreen} />
          </Switch>
        </MeContext.Provider>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default withAuthenticator(
  App,
  false,
  [],
  undefined,
  AmplifyTheme,
  AmplifySignUpConfig
);
