import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import * as userUtil from "../util/userUtil";
import * as UserApi from "../apis/UserApi";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ROLES from "../enums/ROLES";
import { getEnumFromKey } from "../util/commonUtil";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import PickAUserDialog from "./PickAUserDialog";
import MeContext from "../contexts/MeContext";
import OrgContext from "../contexts/OrgContext";
import Snackbar from "@material-ui/core/Snackbar";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  roleKeySelector: {
    minWidth: 125,
  },
});

function UsersTable(props) {
  const { classes, userEntries = [] } = props;

  const [loadingUserId, setLoadingUserId] = useState();
  const [loadingGeneric, setLoadingGeneric] = useState();
  const userIds = userEntries.map(userEntry => userEntry.userId);
  const [users, setUsers] = useState(UserApi.getUsersFromCache(userIds));
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [snack, setSnack] = React.useState({ open: false, note: null });

  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  useEffect(() => {
    UserApi.getUsers(userIds).then(users => {
      setUsers(users);
    });
  }, [userEntries]);

  function getTableRow(user) {
    if (!user) return null;

    let picture;
    if (user.picture) {
      picture = user.picture;
    }

    const orgUser = userEntries.find(aUser => aUser.userId === user.userId);

    if (!orgUser || !orgUser.roleKey) return null;

    const role = getEnumFromKey(ROLES, orgUser.roleKey);

    const meString = me.userId === user.userId ? " (me)" : "";

    return (
      <React.Fragment key={`${user.userId}-section`}>
        {loadingUserId === user.userId && (
          <TableRow key={`${user.userId}-load`} className={classes.loadingRow}>
            <TableCell colSpan={4} className={classes.loadingCell}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
        <TableRow key={user.userId} hover={true}>
          <TableCell component="th" scope="row">
            <Avatar alt={userUtil.getDisplayName(user)} src={picture}>
              {userUtil.getInitials(user)}
            </Avatar>
          </TableCell>
          <TableCell component={Link} to={userUtil.getProfileLink(user.userId)}>
            <Typography variant="body1">
              {userUtil.getDisplayName(user)}
              {meString}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{getRoleForm(user, role)}</Typography>
          </TableCell>
          {userUtil.canDo(me, ROLES.READ_WRITE, org) && (
            <TableCell>
              <IconButton
                aria-label="removeIcon"
                onClick={() => handleRemoveUser(user)}
                disabled={
                  //user cant delete admin users unless they are an admin
                  role.key === ROLES.ADMIN.key &&
                  !userUtil.canDo(me, ROLES.ADMIN, org)
                }
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  }

  async function handleRoleChange(user, role) {
    setLoadingUserId(user.userId);
    await props.handleRoleChange(user, role);
    setLoadingUserId();
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} role updated to ${role.title}`,
    });
  }

  async function handleRemoveUser(user) {
    setLoadingUserId(user.userId);
    await props.handleRemoveUser(user);
    setLoadingUserId();
  }

  async function handleAddUser(user) {
    setLoadingGeneric(true);
    setShowAddUserDialog(false);
    await props.handleAddUser(user);
    setLoadingGeneric(false);
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} has been added`,
    });
  }

  function getRoleForm(user, role) {
    const meRO = userUtil.canDo(me, ROLES.READ_ONLY, org);
    const meRW = userUtil.canDo(me, ROLES.READ_WRITE, org);
    const meADMIN = userUtil.canDo(me, ROLES.ADMIN, org);
    const enabled = userUtil.canDo(me, role, org);

    return (
      <FormControl
        variant="outlined"
        //disabled if not enabled or only have RO role
        disabled={!enabled || (meRO && !meRW && !meADMIN)}
      >
        <Select
          value={role}
          onChange={event => handleRoleChange(user, event.target.value)}
          input={
            <OutlinedInput labelWidth={0} className={classes.roleKeySelector} />
          }
        >
          {((enabled && meADMIN) || !enabled) && (
            //if its enabled and me is a admin show the option
            <MenuItem value={ROLES.ADMIN}>{ROLES.ADMIN.title}</MenuItem>
          )}
          {((enabled && meRW) || !enabled) && (
            //if its enabled and me is RW show the option
            <MenuItem value={ROLES.READ_WRITE}>
              {ROLES.READ_WRITE.title}
            </MenuItem>
          )}

          <MenuItem value={ROLES.READ_ONLY}>{ROLES.READ_ONLY.title}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, note: null })}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <AppBar position="static" color="default">
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h5"
                component="h4"
                data-testid="usersCountLabel"
                className={classes.grow}
              >
                Users ({userEntries.length})
              </Typography>
            </Grid>
            <Grid item>
              {userUtil.canDo(me, ROLES.READ_WRITE, org) && (
                <Fab
                  data-testid="addUserButton"
                  size="small"
                  color="primary"
                  aria-label="Add"
                  onClick={() => setShowAddUserDialog(true)}
                >
                  <AddIcon />
                </Fab>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loadingGeneric && <LinearProgress data-testid="progressBar" />}
      {users && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Avatar</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Role</Typography>
                </TableCell>
                {userUtil.canDo(me, ROLES.READ_WRITE, org) && (
                  <TableCell>
                    <Typography>Remove</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => {
                return getTableRow(user);
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
      {showAddUserDialog && (
        <PickAUserDialog
          handleClose={() => setShowAddUserDialog(false)}
          handleAddUser={handleAddUser}
          addText="Add"
        />
      )}
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(UsersTable);
